import React, { Component } from "react";
import Lazyload from './lazyload.jsx';
import CSSTransition from 'react-transition-group/CSSTransition';
import { htmlDecode } from '../../utils/';
import ProductBase from './product-base.jsx';
import productFallbackImage from '../../config.json';

class CustomContentItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageVisible: false
        };

        this.handleLazyloadVisible = this.handleLazyloadVisible.bind(this);
    }

    handleLazyloadVisible() {
        this.setState({
            imageVisible: true
        });
    }

    render() {
        let url, modified;
        let videoModel, playBtn = "";
        if (!this.props.cardType) {
            url = this.props.cardBtnUrl ? this.props.cardBtnUrl : this.props.pageUrl;
            if (this.props.cardBtnUrl && this.props.cardBtnUrl.includes("youtube.com")) {
                videoModel = "js-video-modal";
                playBtn = <svg class="icon icon-play card-media-play"><use xlinkHref="#icon-play"></use></svg>;
            }
        }

        if (this.props.useDateModified) {
            modified = <p className="card-meta modified"><span className="card-modified heading">Date Created/Modified:</span> <span className="card-modified">{htmlDecode(this.props.dateModified)}</span></p>

        }
        const addMargin = this.props.cardSubHeading ? '' : 'top-27px';
        const urlTarget = this.props.urlTarget && this.props.urlTarget === '_blank' ? '_blank' : '';
        const imgAlt = this.props.cardImageAlt ? this.props.cardImageAlt : this.props.cardTitle;
        return (
            !this.props.cardType ?
                <div className={this.props.colClass}>
                    <article className="card">
                        <a target={urlTarget} href={url} className={videoModel}>
                            {this.props.cardSubHeading && <span className="card-type">{this.props.cardSubHeading}</span>}
                            <div className={`card-media scrim ${addMargin} ${this.props.tallCard ? 'tall-card' : ''}`}>
                                <CSSTransition in={this.state.imageVisible} timeout={250} classNames="fade">
                                    <Lazyload height="100%" rootMargin="100px" onVisible={this.handleLazyloadVisible}>
                                        <div style={{height: '100%'}}>
                                            {this.props.cardImage &&
                                                <img srcSet={`${this.props.cardImage.replace('~', '')} 1x`} alt={imgAlt} />}
                                            {playBtn}
                                        </div>
                                    </Lazyload>
                                </CSSTransition>
                            </div>
                            {this.props.cardTitle && <h3 className={`card-title`}>{this.props.cardTitle}</h3>}
                            {modified}
                            {this.props.cardDescription && <p className="card-desc content">{htmlDecode(this.props.cardDescription)}</p>}
                            {this.props.cardBtnText && <p className="btn-link">{`${this.props.cardBtnText} >`}</p>}
                        </a>
                    </article>
                </div> :
                <ProductBase userType={true} colClass={this.props.colClass} {...this.props} />
        );
    }
}

CustomContentItem.defaultProps = {
    colClass: 'col-lg-4 col-sm-6',
    cardBtnText: 'View',
    cardImage: productFallbackImage
};

export default CustomContentItem;